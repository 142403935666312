body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.breadCrumbLink {
  color: #455a64;
  font-weight: bold;
  text-decoration: none;
}

.breadCrumbLink:hover {
  text-decoration: underline;
}

/* CalendarView.css */
.calendar-container {
  padding: 1rem;
}

.calendar-wrapper {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.popup-content {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  width: 90%;
  max-width: 480px;
  padding: 1.5rem;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.popup-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 0.25rem;
}

.close-button:hover {
  color: #333;
}

.close-icon {
  height: 1.25rem;
  width: 1.25rem;
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.detail-item {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
}

.detail-icon {
  height: 1.25rem;
  width: 1.25rem;
  color: #666;
  margin-top: 0.25rem;
  flex-shrink: 0;
}

.detail-content {
  flex: 1;
}

.detail-content p {
  color: #4a4a4a;
  font-size: 1rem;
  margin: 0;
  line-height: 1.5;
}

/* Date range styles */
.date-range {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin: 0.5rem 0;
}

.date-range-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-left: 0.5rem;
  border-left: 2px solid #e5e7eb;
}

.date-item {
  color: #4a4a4a;
  font-size: 1rem;
  line-height: 1.5;
}

/* Leave type badge styles */
.leave-type-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: #e8eef9;
  color: #476fdc;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1rem;
  color: #666;
}

.error {
  text-align: center;
  padding: 2rem;
  font-size: 1rem;
  color: #dc2626;
}